<script>
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
    props: {
        value: Object,
        v: Object,
        submitted: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            editor: ClassicEditor,
        }
    },
    components: {        
        ckeditor: CKEditor.component,
    },
    methods: {
        
    },
};
</script>

<template>
    <div class="row">
        <div class="form-group col-12">
            <label for="description">Descripción</label>
            <ckeditor id="description" v-model="value.description" :editor="editor"></ckeditor>
            <div v-if="v.advantage.description.$error && submitted" class="invalid-feedback d-block">Ingresa la descripción de la ventaja</div>
        </div>
        <div class="form-group col-12">
            <label for="description">Descripción en inglés</label>
            <ckeditor id="english_description" v-model="value.english_description" :editor="editor"></ckeditor>
            <div v-if="v.advantage.english_description.$error && submitted" class="invalid-feedback d-block">Ingresa la descripción en inglés de la ventaja</div>
        </div>
    </div> 
</template>
<script>
import { required } from "vuelidate/lib/validators";
import Modal from "@/components/modals/modal-form.vue";
import api from '@/helpers/apirest/api';
import SelectAutocompleteField from "@/components/forms/select-autocomplete-field.vue";

const initialState = () => {
    return {
        submitted: false,
        establishment: {
            collaborator_id: null,
            population_id: null,
            trade_name: null,
            address: null,
            lat: null,
            lng: null,
            postcode: null,
            phone: null,
            email: null,
            website: null,
            facebook_url: null,
            instagram_url: null,
            sync_with_eyca: true,
        },
        center: { lat: 40, lng: -3 },
        placeChangedTriggered: false,
    };
}

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
        id: Number,
        collaborator: Object
    },
    data() {
        return initialState();
    },
    components: {
        Modal,
        SelectAutocompleteField,
    },
    computed: {
        centerMap(){
            return { lat: parseFloat(this.establishment?.lat || this.center.lat), lng: parseFloat(this.establishment?.lng || this.center.lng) }
        }
    },
    validations: {
        establishment: {
            population: { },
            trade_name: { required },
            address: { },
            lat: { },
            lng: { },
            postcode: { },
            phone: { },
            email: { },
            website: { },
            facebook_url: { },
            instagram_url: { },
            sync_with_eyca: { },
        }
    },
    methods: {
        async shown() {

            if (this.id) {
                const response = await api.get({
                    url: `collaborators/${this.collaborator?.id}/establishments/${this.id}`,
                    config: {
                        withLoading: true
                    }  
                });

                const establishment = response?.data?.data;
                this.establishment = this.getCollaboratorWrapper(establishment);
            }
        },
        getCollaboratorWrapper(establishment) {
            if(!establishment?.user) {
                establishment.user = initialState().establishment.user;
            }
            return establishment;
        },
        getPostalCode(place) {
            if (!place.address_components) return null;

            const postalComponent = place.address_components.find(component =>
                component.types.includes('postal_code')
            );

            return postalComponent ? postalComponent.long_name : null;
        },
        setLatLng(position){
            this.establishment.lat = position?.lat;
            this.establishment.lng = position?.lng;
        },
        setAddress(event){   
            setTimeout(()=>{
                if (!this.placeChangedTriggered) {  
                    this.establishment.address = event?.target?.value;    
                }
                this.placeChangedTriggered = false;
            }, 300)
        },
        updateMarkerPosition(event) {
            this.setLatLng(event.latLng.toJSON());
        },
        handlePlaceChanged(place) {
            this.placeChangedTriggered = true;
            if (place.geometry) {
                this.establishment.address = place.formatted_address; 
                this.setLatLng(place.geometry.location.toJSON());
            } else {
                alert('Dirección no encontrada. Por favor, selecciona una dirección válida.');
            }

            const postalCode = this.getPostalCode(place);
            if(postalCode){
                this.establishment.postcode = postalCode;
            }
        },
        updateMarkerPositionManual() {
            const lat = parseFloat(this.establishment.lat);
            const lng = parseFloat(this.establishment.lng);

            if (!isNaN(lat) && !isNaN(lng)) {
                this.setLatLng({ lat, lng });
            } else {
                console.warn("Latitud o longitud no válidas:", lat, lng);
            }
        },
        async confirm() {

            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            const data = this.establishment;
            data.population_id = this.establishment?.population?.id;
            const response = await api.save({
                url: `collaborators/${this.collaborator?.id}/establishments`,
                id: this.establishment?.id,
                data: data,
                config: {
                    withLoading: true
                }
            });

            this.$bvModal.hide(this.modalId);
            this.$emit("onSuccess", response?.data?.data);
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        },
        resetModal() {
            Object.assign(this.$data, initialState());
        },
    }
};
</script>

<template>
    <Modal :title="'Formulario de establecimiento'" :id="modalId" @confirm="confirm" @cancel="cancel" @shown="shown" @hidden="resetModal" :size="'extra-xl'">
        <div class="row">

            <div class="col-lg-4 col-md-12 col-xs-12 mb-2">
                <div class="card h-100">
                    <div class="card-body">
                        <h4 class="card-title">Datos básicos</h4>
                        <div class="row">
                            <div class="form-group col-12">
                                <label for="trade_name">Nombre</label>
                                <input id="trade_name" v-model="establishment.trade_name" type="text" class="form-control" placeholder="Ingresa un nombre"
                                    :class="{ 'is-invalid': $v.establishment.trade_name.$error && submitted }" />
                                <div v-if="!$v.establishment.trade_name.required && submitted" class="invalid-feedback">Nombre es requerido</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="phone">Teléfono</label>
                                <input id="phone" v-model="establishment.phone" type="text" class="form-control" placeholder="Ingresa un teléfono"
                                    :class="{ 'is-invalid': $v.establishment.phone.$error && submitted }" />
                                <div v-if="!$v.establishment.phone.required && submitted" class="invalid-feedback">Teléfono es requerido</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="email">Email</label>
                                <input id="email" v-model="establishment.email" type="text" class="form-control" placeholder="Ingresa un email"
                                    :class="{ 'is-invalid': $v.establishment.email.$error && submitted }" />
                                <div v-if="!$v.establishment.email.required && submitted" class="invalid-feedback">Email es requerido</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="website">Sitio web</label>
                                <input id="website" v-model="establishment.website" type="text" class="form-control" placeholder="Ingresa el sitio web"
                                    :class="{ 'is-invalid': $v.establishment.website.$error && submitted }" />
                                <div v-if="!$v.establishment.website.required && submitted" class="invalid-feedback">Sitio web es requerido</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="facebook_url">Facebook</label>
                                <input id="facebook_url" v-model="establishment.facebook_url" type="text" class="form-control" placeholder="Ingresa la url de Facebook"
                                    :class="{ 'is-invalid': $v.establishment.facebook_url.$error && submitted }" />
                                <div v-if="!$v.establishment.facebook_url.required && submitted" class="invalid-feedback">Facebook es requerido</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="instagram_url">Instagram</label>
                                <input id="instagram_url" v-model="establishment.instagram_url" type="text" class="form-control" placeholder="Ingresa la url de Instagram"
                                    :class="{ 'is-invalid': $v.establishment.instagram_url.$error && submitted }" />
                                <div v-if="!$v.establishment.instagram_url.required && submitted" class="invalid-feedback">Instagram es requerido</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="sync_with_eyca">Sincronizar con EYCA?</label>
                                <div class="custom-control custom-checkbox">
                                    <input
                                        type="checkbox"
                                        v-model="establishment.sync_with_eyca"
                                        class="custom-control-input"
                                        id="sync_with_eyca"
                                    />
                                    <label class="custom-control-label" for="sync_with_eyca">Sí</label>
                                </div>
                            </div>    
                        </div>                         
                    </div>
                </div>
            </div>

            <div class="col-lg-8 col-md-6 col-xs-12 mb-2">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">
                            Datos de dirección
                        </h4>
                        <div class="row">
                            <div class="form-group col-lg-12 col-md-12">
                                <label>Población</label>
                                <SelectAutocompleteField
                                    :url="`/populations`"
                                    :multiple="false"
                                    :placeholder="'Busca una población'"
                                    :label="'fullname'"
                                    v-model="establishment.population"
                                    :class="{ 'is-invalid': $v.establishment.population.$error && submitted }"
                                ></SelectAutocompleteField>
                                <div v-if="!$v.establishment.population.required && submitted" class="invalid-feedback">Población es requerida</div>
                            </div>
                            <div class="form-group col-lg-6 col-md-6">
                                <label for="address">Dirección</label>
                                <GmapAutocomplete
                                    class="form-control"
                                    @place_changed="(event) => handlePlaceChanged(event)"
                                    @change="(event) => setAddress(event)"
                                    ref="autocomplete"
                                    :value="establishment.address"
                                    placeholder="Buscar dirección"
                                />
                            </div>
                            <div class="form-group col-lg-6 col-md-6">
                                <label for="postcode">Código postal</label>
                                <input id="postcode" v-model="establishment.postcode" type="text" class="form-control" placeholder="Ingresa un código postal"
                                    :class="{ 'is-invalid': $v.establishment.postcode.$error && submitted }" />
                                <div v-if="!$v.establishment.postcode.required && submitted" class="invalid-feedback">Código postal es requerido</div>
                            </div>
                            <div class="form-group col-lg-6 col-md-6">
                                <label for="lat">Latitud</label>
                                <input id="lat" v-model="establishment.lat" type="number" class="form-control" placeholder="Ingresa un nombre"
                                    :class="{ 'is-invalid': $v.establishment.lat.$error && submitted }" @input="updateMarkerPositionManual()"/>
                            </div>
                            <div class="form-group col-lg-6 col-md-6">
                                <label for="lng">Logitud</label>
                                <input id="lng" v-model="establishment.lng" type="number" class="form-control" placeholder="Ingresa un nombre"
                                    :class="{ 'is-invalid': $v.establishment.lng.$error && submitted }" @input="updateMarkerPositionManual()"/>
                            </div>
                            <div class="col-12">
                                <gmap-map :center="centerMap" :zoom="9" style="height: 400px">
                                    <gmap-marker
                                        v-if="establishment?.lat && establishment?.lng"
                                        :position="{ lat: parseFloat(establishment?.lat), lng: parseFloat(establishment?.lng) }"
                                        :clickable="true"
                                        :draggable="true"
                                        :title="establishment?.address"
                                        @dragend="updateMarkerPosition($event)" 
                                    ></gmap-marker>
                                </gmap-map>
                                <!-- @click="center = { lat: parseFloat(location?.lat), lng: parseFloat(location?.lng) }" -->
                            </div>
                        </div>                    
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</template>